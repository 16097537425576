import React, { useState } from 'react';

const DeleteAccount = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    reason: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const reasons = [
    'I created the account by accident',
    'I lost access to the account',
    'I just want to cancel',
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    // Prepare the payload for the API Gateway
    const payload = {
      username: formData.username,
      email: formData.email,
      reason: formData.reason,
    };

    try {
      const response = await fetch('https://rwyexqbad7.execute-api.us-east-1.amazonaws.com/default/shmusik-delete-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Ensure the payload is stringified here
      });

      const result = await response.json();

      // console.log("RESULT IS: " + result);
      // console.log("RESPONSE IS: " + response);

      // console.log("RESULT STATUS CODE IS: " + result.statusCode);
      // console.log("RESPONSE STATUS CODE IS: " + response.status);

      // console.log("RESULT BODY IS: " + result.body);
      // console.log("RESPONSE BODY IS: " + response.statusText);


      // Handle different status codes from Lambda
      switch (result.statusCode) {
        case 200:
          // Success response
          setMessage(result.message || 'Account deletion request submitted successfully.');
          break;
        case 400:
          // Bad request (e.g., missing fields)
          setMessage(result.message || 'Bad request. Please check your input.');
          break;
        case 404:
          // Not Found (e.g., user does not exist)
          setMessage(result.message || 'User does not exist.');
          break;
        case 500:
          // Internal Server Error (e.g., something went wrong on the server)
          setMessage(result.message || 'Server error. Please try again later.');
          break;
        default:
          // Other status codes
          setMessage(result.message || 'An unexpected error occurred. Please try again.');
          break;
      }
    } catch (error) {
      console.error('Error submitting request:', error);
      setMessage('Unable to submit your request. Please check your connection and try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Request Account Deletion</h1>
      <form
        onSubmit={handleSubmit}
        style={{
          margin: '0 auto',
          maxWidth: '600px',
          textAlign: 'left',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
        }}
      >
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            placeholder="Enter your username"
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Enter your email"
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="reason">Reason for Deleting:</label>
          <select
            id="reason"
            name="reason"
            value={formData.reason}
            onChange={handleChange}
            required
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          >
            <option value="">-- Select a reason --</option>
            {reasons.map((reason, index) => (
              <option key={index} value={reason}>
                {reason}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          disabled={loading}
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: loading ? '#ddd' : '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
        >
          {loading ? 'Processing...' : 'Submit Request'}
        </button>
      </form>

      {message && (
        <p
          style={{
            marginTop: '20px',
            padding: '10px',
            backgroundColor: '#f8d7da',
            color: '#721c24',
            border: '1px solid #f5c6cb',
            borderRadius: '4px',
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default DeleteAccount;
